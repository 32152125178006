<template>
    <pfoGalerie />


</template>

<script>
    import pfoGalerie from './components/pfoGalerie.vue'

    export default {
        name: 'App',
        components: {
            pfoGalerie
        }
    }
</script>


