<template>
    <section class="fotos">
        <div class="row">
            <div class="how-to">
                <item-element v-if="Galerie.texte && Galerie.texte.headline_so_einfach_gehts">
                    <h2 v-html="Galerie.texte.headline_so_einfach_gehts"></h2>
                </item-element>
                <ol>
                    <li v-for="(item, index) in Galerie.howtoList" :key="index">{{item}}</li>
                </ol>
            </div>
            <div class="discount-batch top" v-html="Galerie.discount_batch"></div>
            <div class="top" v-html="Galerie.download_pauschal"></div>
        </div>
        <div class="lang-switch" v-html="Galerie.lang_switch"></div>
        <h1 v-html="Galerie.name"></h1>
        <div>
            <ul id="alle-fotos">
                <li v-for="(photo, index) in Galerie.fotos" :key="index">
                    <figure @click.prevent="showFormatSelection(photo.id, photo.url_big, photo.prev, photo.next, photo.download)">
                        <img :src="photo.url_big" alt=""/>
                        <figcaption>
                            <button>
                                {{Galerie.texte.bestellen_button}}
                            </button>
                            <a v-if="photo.download_link" class="button" @click.stop :href="photo.download_link"
                               target="_blank"
                               download><i class="fa fa-download"></i> Download</a>

                        </figcaption>
                    </figure>
                </li>
            </ul>
        </div>
        <div class="bottom" v-html="Galerie.download_pauschal"></div>
        <div class="discount-batch bottom" v-html="Galerie.discount_batch"></div>
        <section id="format-dialog" :class="dialog" @click.prevent="closeFormatDialog($event )">
            <div class="format-auswahl" :class="kat">
                <!--<h2>Ihre Auswahl </h2>-->
                <p class="close-link-top"><a href="#" id="close-icon" class="close-icon" title="schließen"
                                             @click.prevent="this.close()">x</a>
                </p>
                <div id="preview-image-wrapper"><img id="preview-image" alt="" title="" v-bind:src="recentImage"></div>
                <nav id="prev-next">
                    <a id="prev" v-if="prev > 0" href="#" @click.prevent="this.close( prev )">vorheriges Bild</a>
                    <a id="next" v-if="next > 0" href="#" @click.prevent="this.close(next)">nächstes Bild</a>
                </nav>
                <h3>Produkt auswählen</h3>
                <p :class="'nr-of-kats-' + Formate.anzahl_kats">
                    <small id="dl-advice" class="block ta-re">Für Download-Dateien ändere hier die Auswahl.</small>
                    <select name="kat-selection" id="kat-selection" @change.prevent="setWrapperKat($event)">
                        <option v-for="(kat_option, index) in Formate.kat_names" :key="index"
                                :class="'main_'+index"
                                :selected="index === kat_default"
                                :value="index"> {{kat_option.name}}
                        </option>
                    </select>
                </p>
                <p id="single-selects">
                    <select v-for="(kat_option, index) in Formate.formate_by_kat" :key="index"
                            :class="index"
                            class="single-select"
                            :id="index"
                            :ref="index"
                            :data-default-format="Formate.default_format[index]"
                            @change.prevent="setFormatId($event)">
                        <option v-for="option in kat_option"
                                :key="option"
                                :value="option.id "
                                :data-thumb="option.thumb"
                                class="kat_option"
                                :class="option.kat"
                                :data-max="option.max"
                                :data-format-id="option.id"
                                :selected="option.id == Formate.default_format[index]"
                                :data-surface="option.product_key"
                                :data-test-1="option.id"
                                :data-test-2="Formate.default_format[index]"
                                :data-image="option.image"
                                :data-ordertype="option.ordertype"
                        > {{option.desc}}
                        </option>
                    </select>
                </p>
                <p class="add-minus-x">
                    <a href="#" rel="nofollow"
                       id="format-link"
                       class="open-help-layer mt-10 hidden"
                       data-wordpress="0"
                       data-format=""
                       :data-surface="dataSurface"
                       :data-ordertype="dataOrdertype"
                       :data-image="dataImage"
                    >
                        <span class="ta-re">
                        <small class="block">
                    <img
                            id="format-thumb"
                            class="open-help-layer-x"
                            style="border: 1px solid #ccc;"
                            :src="dataThumb"
                    ><br>
                        » Produkt-Info</small> </span> </a>
                    <strong class="label">Anzahl</strong>
                    <select v-model="anzahl"
                            id="anzahl"
                            :max="maxAnzahl">
                        <option v-for="(i,index) in anzahlOptionsConf" :value="index" :key="index">{{ i}}</option>
                    </select>
                    <input id="foto-id"
                           name="foto-id"
                           type="hidden"
                           v-model="fotoId">
                    <input v-model="formatId"
                           id="format-id"
                           name="format-id"
                           type="hidden">
                    <button name="add-button"
                            id="add-button"
                            @click.prevent="addToCart(this.anzahl, this.formatId)"> In den Warenkorb
                    </button>
                    <span id="loading-image"> <img :src="statusGif" :class="statusWaitForCart"></span>
                </p>
                <div id="inlineError" class="slide-up">
                    <p>Downloads können pro Foto nur 1x in den Warenkorb gelegt
                        werden.
                    </p>
                </div>
                <item v-for="(format ,index) in itemsProFotos"
                      :value="index" :key="index">
                    <item-element v-if="index == this.fotoId ">
                        <p class="your-selection"><strong>Ihre Auswahl</strong></p>
                        <ul class="recent-selection" :class="hiddenAuswahl">
                            <li v-for="(oneFormat,indexFormat) in format " :value="indexFormat" :key="indexFormat">
                                {{
                                oneFormat.anzahl}} x {{ oneFormat.name}}
                                <button class="close-icon" name="remove-foto-from-cart"
                                        @click.prevent="removeProductFromCart($event, oneFormat.format)">
                                    x
                                </button>
                            </li>
                        </ul>
                    </item-element>
                </item>
                <ul class="actions">
                    <li><a href="#" id="close-link" class="close-link" @click.prevent="this.close()">Zurück zur
                        Galerie</a></li>
                    <li><a :href="Formate.cart_link" id="cart-link" class="close-link">Direkt zur Kasse</a></li>
                </ul>
                <div id="add-debug" v-html="cartDebug"
                     style="border: 1px solid limegreen;text-align: left;">
                </div>
            </div>
        </section>
    </section>
</template>
<script>
    import ApiService from '../../services/api';
    import closeMixin from '../../mixins/close';

    export default {
        name: 'pfoGalerie',
        props: {},
        data() {
            this.dialog = 'hidden',
                this.statusWaitForCart = 'hidden',
                // this.parts = document.location.href.split('/'),
                this.sessionId = window.sessionId,
                this.val_1 = window.val_1 ? window.val_1 : false ,
                this.val_2 = window.val_2 ? window.val_2 : false,
                this.val_3 = window.val_3 ? window.val_3 : false,
                this.is_wordpress = window.is_wordpress,
                this.getFotos(),
                this.getFormate(),
                this.loadingGif = 'https://profi-fotos-online.com/_img/loading.gif',
                this.loadingReady = 'https://profi-fotos-online.com/_img/ok-neu.png',
                this.hidden_download_optoin = 0,
                this.statusGif = this.loadingGif;
            return {
                Galerie: [],
                Formate: [],
                dialog: this.dialog,
                kat: this.kat,
                formatId: this.formatId,
                fotoId: this.fotoId,
                kat_default: this.kat_default,
                selectedItem: this.selectedItem,
                recentImage: this.recentImage,
                anzahl: this.anzahl,
                maxAnzahl: this.maxAnzahl,
                dataThumb: this.dataThumb,
                dataSurface: this.dataSurface,
                dataImage: this.dataImage,
                dataOrdertype: this.dataOrdertype,
                minusDisabled: this.minusDisabled,
                addDisabled: this.addDisabled,
                cartDebug: this.cartDebug,
                anzahlOptionsConf: this.anzahlOptionsConf,
                selectedOption: this.selectedOption,
                itemsProFotos: this.itemsProFotos,
                statusWaitForCart: this.statusWaitForCart,
                statusGif: this.statusGif,
                latestCart: this.latestCart,
                sessionId: this.sessionId,
                visitor: this.visitor,
                recentSelect: this.recentSelect,
                formatLink: this.formatLink
            }
        },
        mixins: [closeMixin],
        methods: {


            getFotos: function () {
                var that = this;
                var val_1 = that.val_1 ? that.val_1 : '';
                var val_2 = that.val_2 ? that.val_2 : '';
                var val_3 = that.val_3 ? that.val_3 : '';
                var sessionId = that.sessionId ? that.sessionId : false;
                var is_wordpress = that.is_wordpress ? that.is_wordpress : 0;
                // console.log('parts:', that.parts);
                ApiService.doAjaxCall({
                    task: 'get-images',
                    'val-1': val_1,
                    'val-2': val_2,
                    'val-3': val_3,
                    sessionId: sessionId,
                    is_wordpress: is_wordpress
                }).then(function (response) {
                    that.Galerie = response.data;
                    that.latestCart = response.data.cart;
                    that.setItemsInCartList();
                    that.saveSessenIdToLocalStorage(response.data.session_id, response.data.visitor);
                    // console.log('that.Galerie ', that.Galerie);
                    // console.log('sessionId:', that.sessionId);
                    // console.log('is_wordpress:', that.is_wordpress);
                    try {
                        window.app.pfo.fadeOutLoader();
                    } catch (e) {
                        console.log(e, 'e');
                    }
                })
            },
            getFormate: function () {
                var that = this;
                var val_1 = that.val_1 ? that.val_1 : '';
                var val_2 = that.val_2 ? that.val_2 : '';
                var val_3 = that.val_3 ? that.val_3 : '';
                var sessionId = that.sessionId ? that.sessionId : false;
                var is_wordpress = that.is_wordpress ? that.is_wordpress : 0;
                ApiService.doAjaxCall({
                    task: 'get-formate',
                    'val-x': 'xxx',
                    'val-1': val_1,
                    'val-2': val_2,
                    'val-3': val_3,
                    sessionId: sessionId,
                    is_wordpress: is_wordpress
                }).then(function (response) {
                    // console.log('task', task);
                    that.Formate = response.data;
                    that.kat_default = response.data.kat_default;
                    that.kat = response.data.kat_default;
                }).then(function () {
                    that.setFormatOfKat();
                })
            },
            showFormatSelection: function (fotoId, recentImage, prev, next, download) {
                // alert("fotoId: " + fotoId);
                this.hiddenAuswahl = 'hidden';
                if (typeof this.anzahl == 'undefined') {
                    this.anzahl = 1;
                }
                this.dialog = 'open';
                this.fotoId = fotoId;
                this.recentImage = recentImage;
                this.prev = prev;
                this.next = next;
                this.download = download;
                this.setFormatOfKat();
                this.setItemsInCartList();
                this.displayProductThumb();
            },
            startDownload: function (url) {
                alert('url: ' + url);
            },
            setFormatId: function ($event) {
                this.dataThumb = $event.target[$event.target.selectedIndex].getAttribute('data-thumb');
                this.dataSurface = $event.target[$event.target.selectedIndex].getAttribute('data-surface');
                this.dataOrdertype = $event.target[$event.target.selectedIndex].getAttribute('data-ordertype');
                this.dataImage = $event.target[$event.target.selectedIndex].getAttribute('data-image');
                window.dataSurface = this.dataSurface;
                window.dataOrdertype = this.dataOrdertype;
                window.dataImage = this.dataImage;
                this.formatId = $event.target.value;
                this.maxAnzahl = parseInt($event.target[$event.target.selectedIndex].getAttribute('data-max'), 10);
                this.displayProductThumb();
            },
            displayProductThumb: function () {
                this.recentSelect = this.$refs[this.kat];
                if (this.recentSelect.length > 0) {
                    this.dataThumb = this.recentSelect[0][this.recentSelect[0].selectedIndex].getAttribute('data-thumb');
                    this.dataSurface = this.recentSelect[0][this.recentSelect[0].selectedIndex].getAttribute('data-surface');
                    this.dataOrdertype = this.recentSelect[0][this.recentSelect[0].selectedIndex].getAttribute('data-ordertype');
                    this.dataImage = this.recentSelect[0][this.recentSelect[0].selectedIndex].getAttribute('data-image');
                    window.dataSurface = this.dataSurface;
                    window.dataOrdertype = this.dataOrdertype;
                    window.dataImage = this.dataImage;
                    if (!this.formatLink) {
                        this.formatLink = document.getElementById('format-link');
                    }
                    if (this.dataThumb) {
                        if (this.formatLink) {
                            this.formatLink.classList.remove('hidden');
                        }
                    } else {
                        if (this.formatLink) {
                            this.formatLink.classList.add('hidden');
                        }
                    }
                }
            },
            setWrapperKat: function ($event) {
                this.kat = $event.target.value;
                this.setFormatOfKat();
                this.displayProductThumb();
            },
            setFormatOfKat: function () {
                if (this.kat == 'kat_0' && this.download == '0') {
                    this.kat = 'kat_1';
                    let selectElement = document.getElementById('kat-selection');
                    selectElement.value = 'kat_1';
                    this.hidden_download_optoin = 1;
                }
                if (this.download == '1' && this.hidden_download_optoin == 1) {
                    // let kat_0_option = document.querySelector('#kat-selection .main_kat_0');
                    this.hidden_download_optoin = 0;
                }
                let dl_advice = document.querySelector('#dl-advice');

                // console.log('dl_advice');
                // console.log( dl_advice);

                let kat_0_option = document.querySelector('#kat-selection .main_kat_0');
                if (this.download == '0') {
                    if (kat_0_option) {
                        kat_0_option.style.display = 'none';
                    }
                    dl_advice.style.display = 'none';
                    // console.log('DL hide');
                } else {
                    if (kat_0_option) {
                        kat_0_option.style.display = '';
                    }
                    dl_advice.style.display = '';
                    // console.log('DL show');
                }

                //alert("this.download: " + this.download + " | this.kat: " + this.kat + "  | this.hidden_download_optoin: " + this.hidden_download_optoin);
                this.recentSelect = this.$refs[this.kat];
                if (this.recentSelect.length > 0) {
                    this.formatId = this.recentSelect[0].value;
                    this.maxAnzahl = parseInt(this.recentSelect[0][this.recentSelect[0].selectedIndex].getAttribute('data-max'), 10);
                }
                if (this.latestMaxAnzahl != this.maxAnzahl) {
                    if (this.maxAnzahl == 1) {
                        this.selectedOption = 1;
                        this.anzahlOptionsConf = {0: 0, 1: 1};
                    } else {
                        if (typeof this.latestAnzahl == "undefined") {
                            this.selectedOption = 1;
                        } else {
                            this.selectedOption = this.latestAnzahl;
                        }
                        this.anzahlOptionsConf = {0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10};
                    }
                    this.anzahl = this.selectedOption;
                }
                this.latestMaxAnzahl = this.maxAnzahl;
                this.latestAnzahl = this.anzahl;
            },
            addToCart: function (anzahl, formatId) {
                var that = this;
                this.statusWaitForCart = '';
                this.statusGif = this.loadingGif;
                this.sessionId = localStorage.getItem('pfo_session');
                this.visitor = localStorage.getItem('pfo_visitor');
                var is_wordpress = that.is_wordpress ? that.is_wordpress : 0;
                ApiService.doAjaxCall({
                    task: 'save-cart',
                    foto: this.fotoId,
                    format: formatId,
                    anzahl: anzahl,
                    sessionId: this.sessionId,
                    is_wordpress: is_wordpress
                }).then(function (response) {
                    // console.log(response.data, 'response');
                    // console.log(response.data.debug, 'response.debug');
                    // console.log(response.data.anzahl_cart, 'response.data.anzahl_cart');
                    that.cartDebug = response.data.debug;
                    that.setItemsInCart(response.data.anzahl_cart);
                    that.latestCart = response.data.cart;
                    that.setItemsInCartList();
                })
            },
            setItemsInCart: function (anzahl) {
                var cartIcon = document.getElementById('cart-anzahl');
                var cartLi = document.getElementById('cart-li');
                try {
                    var spanAnzahl = document.createTextNode(anzahl.toString());
                    var firstChild = cartIcon.childNodes;
                    if (firstChild.length < 1) {
                        cartIcon.appendChild(spanAnzahl);
                    } else {
                        cartIcon.replaceChild(spanAnzahl, firstChild[0]);
                    }
                    if (anzahl > 0) {
                        cartLi.classList.remove('empty');
                    } else {
                        cartLi.classList.add('empty');
                    }
                } catch (e) {
                    console.log(e, '##### ERROR #####')
                }
            },
            setItemsInCartList: function () {
                this.itemsProFotos = this.latestCart;
                this.hiddenAuswahl = '';
                var that = this;
                setTimeout(function () {
                    that.statusGif = that.loadingReady
                }, 250);
            },
            removeProductFromCart: function ($event, format) {
                var decision = confirm('Soll diese Formatauswahl wirklich entfernt werden?');
                if (decision) {
                    this.addToCart(0, format);
                }
            },
            closeFormatDialog: function ($event) {
                // console.log($event.target.id, '$event.target');
                if ($event.target.id == 'format-dialog') {
                    this.close();
                }
                if ($event.target.id == 'cart-link') {
                    window.location.href = $event.target.href;
                }
            },
            saveSessenIdToLocalStorage: function (sessionId, visitor) {
                if (sessionId != 'no-session-id') {
                    try {
                        localStorage.setItem('pfo_session', sessionId)
                    } catch (e) {
                        console.log(e, 'e');
                        throw new Error('error: ' + e);
                    }
                } else {
                    throw new Error('Session-ID fehlt: ' + sessionId);
                }
                if (visitor != 'no-session') {
                    try {
                        localStorage.setItem('pfo_visitor', visitor)
                    } catch (e) {
                        console.log(e, 'e');
                        throw new Error('error: ' + e);
                    }
                } else {
                    throw new Error('Visitor fehlt: ' + visitor);
                }
            },
            close(prevnext) {
                // alert('prevnext: ' + prevnext);
                // var last = this.recentImage;
                this.dialog = 'hidden',
                    this.statusWaitForCart = 'hidden',
                    this.statusGif = '',
                    this.recentImage = '';
                // alert(typeof prevnext);
                if (typeof prevnext != "undefined" && prevnext != '') {
                    this.showFormatSelection(this.Galerie.fotos[prevnext]['id'], this.Galerie.fotos[prevnext]['url_big'], this.Galerie.fotos[prevnext]['prev'], this.Galerie.fotos[prevnext]['next'], this.Galerie.fotos[prevnext]['download']);
                }
                return false;
            },
            handleKeydown(event) {
                if (event.key === 'ArrowLeft') {
                    this.onLeftArrowClick();
                } else if (event.key === 'ArrowRight') {
                    this.onRightArrowClick();
                }
            },
            onLeftArrowClick() {
                alert('Pfeil nach links gedrückt');
                // Füge hier die Logik hinzu, die bei einem Klick auf den linken Pfeil ausgeführt werden soll
            },
            onRightArrowClick() {
                alert('Pfeil nach rechts gedrückt');
                // Füge hier die Logik hinzu, die bei einem Klick auf den rechten Pfeil ausgeführt werden soll
            }
        },
    }
</script>