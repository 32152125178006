import axios from 'axios'


var baseUrl = '/';
var path = 'json/';
var file = 'angular-api.php';
var task = '?task=';
var url_home = window.url_home;

// console.log(url_home, 'url_home ');

if (url_home != '') {
    baseUrl = url_home + '/';
}

var devMode = false;

var currentUrl = window.location.href;


if (currentUrl.indexOf('___vue-dev') > 0 || process.env.NODE_ENV == 'development') {
    devMode = true;
    baseUrl = 'https://api.pfo.photos/';
    console.log("mode is " + process.env.NODE_ENV)
    path = '';
}

// var apiUrl = baseUrl + path + file + task + '&dev=' + devMode;

// console.log(baseUrl, 'baseUrl ');


const instance = axios.create({
    baseURL: baseUrl
})

class ApiService {
    doAjaxCall(param) {
        // console.log('param', param)
        // console.log(path + file + task + param['task'] +'&dev=' + devMode, 'path + file + task + \'&dev=\' + devMode')

        var callUrl = path + file + task + param['task']
            + '&dev=' + devMode
            + '&is_wordpress=' + param['is_wordpress']
            + (param['foto'] ? '&foto=' + param['foto'] : '')
            + (param['format'] ? '&format=' + param['format'] : '')
            + (param['val-1'] ? '&val-1=' + param['val-1'] : '')
            + (param['val-2'] ? '&val-2=' + param['val-2'] : '')
            + (param['val-3'] ? '&val-3=' + param['val-3'] : '')
            + (param['anzahl'] ? '&anzahl=' + param['anzahl'] : '')
            + (param['sessionId'] ? '&sessionId=' + param['sessionId'] : '')

        ;
        // if (param['sessionId']) {
        //     callUrl += callUrl + '&sessionId=' + param['sessionId'];
        // }
        // callUrl += callUrl + '&nix=' + 555;

        var data = instance.get(callUrl);

        // console.log('callUrl ', callUrl);
        // console.log('param[\'sessionId\']: ', param['sessionId']);
        // console.log('data', data);

        return data;
    }
}

export default new ApiService()
